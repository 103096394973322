body {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  line-height: 1.7;
}

.heading-logo {
  font-family: 'Leckerli One', sans-serif;
  font-size: 4.5rem;
  font-weight: 400;
  color: $color-black;
}

.heading-1 {
  font-size: 3rem;
  color: $color-black;
}

.heading-2 {
  font-size: 2rem;
  color: $color-black;
}

.heading-3 {
  font-size: 1.5rem;
  color: $color-black;
  letter-spacing: 0.5px;
}

.heading-4 {
  font-size: 1.4rem;
  color: $color-black;
}

.heading-5 {
  font-size: 1.2rem;
  color: $color-black;
}

.heading--button {
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  font-family: inherit;
  background-color: transparent;
  border: none;
}

.error {
  color: $color-red;
  font-size: 1.3rem;
  text-align: center;
}
