.user-card {
  display: flex;
  align-items: center;
  padding: 0.5rem 1.5rem;

  &__details {
    text-align: left;
    margin-left: 1rem;
    line-height: 15px;
    max-width: 70%;
  }

  & > :nth-child(3) {
    margin-left: auto;
  }
}
