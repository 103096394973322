.icon {
  width: $icon-medium-size;
  height: $icon-medium-size;
  color: $color-black;

  &--huge {
    width: $icon-huge-size;
    height: $icon-huge-size;
  }

  &--larger {
    width: $icon-larger-size;
    height: $icon-larger-size;
  }

  &--large {
    width: $icon-large-size;
    height: $icon-large-size;
  }

  &--small {
    width: $icon-small-size;
    height: $icon-small-size;
  }

  &--tiny {
    width: $icon-tiny-size;
    height: $icon-tiny-size;
  }

  &--active {
    fill: none;
    stroke: $color-black;
    stroke-width: 1px;
  }

  &--white {
    color: $color-white;
  }

  &--grey-1 {
    color: $color-grey-1;
  }

  &--button {
    cursor: pointer;
  }

  &--unread {
    &:after {
      content: '';
      width: 4px;
      height: 4px;
      background: $color-red;
      display: block;
      border-radius: 100%;
      margin: 0 auto;
    }
  }

  * {
    width: 100%;
    height: 100%;
  }
}
