.preview-image {
  position: relative;
  cursor: pointer;
  height: 100%;
  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba($color-black, 0.4);
    top: 0;
    left: 0;
    opacity: 0;
    color: $color-white;
    fill: $color-white;

    &:hover {
      opacity: 1;
    }
  }

  &__content {
    @include absoluteCenter;
    display: flex;

    align-items: center;
    font-size: 1.5rem;
    font-weight: 600;
  }

  &__icon {
    display: flex;
    margin: 0 1.5rem;

    & > * {
      margin-right: 1rem;
    }
  }

  &__image {
    height: 100%;
    position: relative;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}
