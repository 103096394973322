.image-grid {
  grid-column: center-start / center-end;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 1fr;
  grid-column-gap: 3rem;
  grid-row-gap: 3rem;
  justify-content: center;
  overflow: hidden;

  @include respond(phone) {
    grid-column-gap: 2px;
    grid-row-gap: 2px;
  }

  & > *:nth-child(18n + 2) {
    grid-column: 2 / -1;
    grid-row: span 2;
  }

  & > *:nth-child(18n + 10) {
    grid-column: 1 / span 2;
    grid-row: span 2;
  }
}
