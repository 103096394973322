.popup-card {
  @include horizontalAbsoluteCenter;
  position: absolute;
  border: none;
  top: 100%;
  filter: drop-shadow(0px 0px 3px $color-grey-2);
  background-color: $color-white;
  min-width: 25rem;
  cursor: initial;
  border-radius: 50rem;

  &__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    line-height: 4rem;
    text-align: center;

    @include respond(phone) {
      line-height: 3rem;
      padding: 2rem 5rem;
    }
  }

  &:before {
    content: '';
    @include horizontalAbsoluteCenter;
    width: 2.5rem;
    height: 1rem;
    bottom: 100%;
    background-color: $color-white;
    clip-path: polygon(50% 0, 100% 100%, 0 100%);
    box-shadow: 0 0 30px 0px $color-grey-1;
  }

  &--left-align {
    transform: translateX(-80%);
    left: 80%;
    width: 50rem;

    &:before {
      transform: translateX(-80%);
      left: 80%;
    }
  }
}
