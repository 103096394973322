// Global app reset
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%; // 1rem = 10px

  @include respond(tab-land) {
    font-size: 56.25%; // 9px
  }

  @include respond(tab-port) {
    font-size: 50%; // 8px
  }

  @include respond(phone) {
    font-size: 62.5%; // 8px
  }
}

body {
  box-sizing: border-box;
  background-color: $color-grey-3;
  @include respond(phone) {
    background-color: $color-white;
  }
}
