.settings-form {
  & > :not(:last-child) {
    margin-bottom: 2rem;
  }

  &__form-group {
    display: grid;
    grid-template-columns: 12rem 1fr;
    grid-column-gap: 4rem;
    align-items: center;

    & > :first-child {
      justify-self: right;
      text-align: right;
    }
  }

  @include respond(phone) {
    &__form-group {
      display: block;
      grid-column-gap: 0;

      &:nth-child(1) {
        display: flex;
        & > :not(:last-child) {
          margin-right: 2rem;
        }
      }
    }
  }
}
