.sidebar {
  grid-column: 7 / center-end;

  &__content {
    padding: 3rem;
    position: fixed;
    width: 360px;
    & > :not(:last-child) {
      margin-bottom: 2rem;
    }
  }

  @include respond(tab-port) {
    display: none;
  }
}
