.sign-up-page {
  display: grid;
  grid-template-rows: repeat(2, min-content);
  grid-template-columns: minmax(35rem, min-content);
  grid-row-gap: 1rem;
  justify-content: center;
  align-items: center;

  grid-row: 2 / span 1;
  grid-column: center-start / center-end;

  margin-top: 2rem;
}
