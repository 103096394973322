.options-dialog {
  max-height: 40rem;
  width: 40rem;
  background-color: $color-white;
  border-radius: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-self: center;
  grid-column: center-start / center-end;
  overflow: hidden;
  backface-visibility: hidden;

  &__button {
    padding: 1.5rem;
    font-family: inherit;
    font-size: 1.4rem;
    border: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;

    &:active {
      background-color: $color-grey-2;
    }

    &--warning {
      font-weight: 700;
      color: $color-red;
    }
  }

  &__title {
    @extend .options-dialog__button;
    cursor: initial;
    &:active {
      background-color: $color-white;
    }
    padding: 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: min-content;
    justify-items: center;
    align-items: center;

    & > :first-child {
      grid-column: 2 / 3;
    }

    & > :last-child {
      margin-left: auto;
      margin-right: 1rem;
    }
  }

  &__button,
  &__title {
    border-bottom: 1px solid $color-grey-2;
  }
}
