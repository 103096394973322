.form-card {
  padding: 2rem 4rem;
  text-align: center;
  & > :not(:first-child) {
    margin-top: 1rem;
  }

  &__form {
    & > * {
      width: 100%;
    }
  }

  @include respond(phone) {
    padding: 2rem 0;
  }
}
