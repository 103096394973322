.feed {
  grid-column: center-start / 7;

  & > :not(:last-child) {
    margin-bottom: 5rem;
  }

  &__bottom {
    text-align: center;
    padding: 2rem;
    & > .icon {
      fill: $color-secondary;
    }
  }

  &__bottom-icon {
    color: $color-secondary;
  }

  @include respond(tab-port) {
    grid-column: center-start / center-end;
  }

  @include respond(phone) {
    grid-column: full-start / full-end;
    & > :not(:last-child) {
      margin-bottom: 0;
      margin-top: 0;
    }
  }
}
