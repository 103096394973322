.skeleton-loader {
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  background-color: $color-grey-2;
  overflow: hidden;

  &--animated {
    position: relative;
    width: 100%;
    height: 100%;
    background-size: 100% 100%;
    background-color: $color-grey-2;
    overflow: hidden;
    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background: linear-gradient(
        90deg,
        rgba(219, 219, 219, 1) 0%,
        rgba(250, 250, 250, 1) 50%,
        rgba(219, 219, 219, 1) 100%
      );
      animation: shimmer 2s linear infinite;
      content: '';
    }
  }
}
