.loader {
  width: 100%;
  height: 100%;
  z-index: 11;

  background-color: rgba($color-black, 0.4);

  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}
