$color-white: #fff;
$color-black: #262626;
$color-red: #ed4956;

$color-grey-1: #999;
$color-grey-2: #dbdbdb;
$color-grey-3: #fafafa;
$color-grey-4: #efefef;

$color-secondary: #0095f6;
$color-secondary-dark: #00376b;

$icon-huge-size: 15rem;
$icon-larger-size: 6rem;
$icon-large-size: 4rem;
$icon-medium-size: 2.7rem;
$icon-small-size: 2rem;
$icon-tiny-size: 1.3rem;

$footer-height: 10rem;
$mobile-header-height: 5rem;
